import React from "react"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Layout, NavigationMenu, NavigationPane, Seo } from "../components"

export default function Producten({ data }) {
  var title = "Producten"
  var hoortoestellenMenu = getImage(data.hoortoestellenMenu)
  var gehoorbeschermingMenu = getImage(data.gehoorbeschermingMenu)

  return (
    <Layout site="audio" page={`/producten`}>
      <Seo title={title} shop="audio" />
      <NavigationMenu>
        <Link to="/producten/hoortoestellen">
          <NavigationPane image={hoortoestellenMenu}>
            Hoortoestellen
          </NavigationPane>
        </Link>
        <Link to="/producten/gehoorbescherming">
          <NavigationPane image={gehoorbeschermingMenu}>
            Gehoorbescherming
          </NavigationPane>
        </Link>
      </NavigationMenu>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    hoortoestellenMenu: file(name: { eq: "hoortoestellen-menu" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, width: 960)
      }
    }
    gehoorbeschermingMenu: file(name: { eq: "gehoorbescherming-menu" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, width: 960)
      }
    }
  }
`
